import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TermsConditions extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { active_site, company_terms, supplier_terms, translator } = this.props;
        
        return (
            <div>
                {
                    !_.isEmpty(company_terms) &&
                    <div className="mb-4">
                        <h2 className="font-weight-bold mb-4">{translator.translate('global.company_terms')}</h2>

                       {
                           company_terms.sections.map((section, key) => {

                               return (
                                   <div key={key} id={"companyTermsSection_" + key}>
                                       <h3 className="mb-0 d-none d-lg-block">{section.title}</h3>

                                       <hr className="mt-2 mb-3 d-print-none" />

                                       <div className="font-small">
                                           <div dangerouslySetInnerHTML={{ __html: section.content }} />
                                       </div>
                                   </div>
                               )
                           })
                       }
                   </div>
                }

                {
                    !_.isEmpty(supplier_terms) &&
                    <div>
                        <h2 className="font-weight-bold mb-4">{translator.translate('global.supplier_terms')}</h2>

                        {
                            supplier_terms.sections.map((section, key) => {

                                return (
                                    <div key={key} id={"supplierTermsSection_" + key}>
                                        <h3 className="mb-0 d-none d-lg-block">{section.title}</h3>

                                        <hr className="mt-2 mb-3 d-print-none" />

                                        <div className="font-small">
                                            <div dangerouslySetInnerHTML={{ __html: section.content }} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}
