import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Switch extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    componentDidMount()
    {
        if (this.props.onChange)
        {
            $('#' + this.props.id).on('change', () => 
            {
                this.props.onChange();
            });
        }
    }
    
    render()
    {
        return (
            <div className="custom-control custom-switch">
                <input 
                    {...this.props}
                    name={this.props.name}
                    type="checkbox" 
                    className="custom-control-input" 
                    id={this.props.id} 
                    defaultValue={this.props.defaultValue}
                    defaultChecked={this.props.defaultChecked}
                />
                <label className="custom-control-label" htmlFor={this.props.id}>{this.props.label || "On / Off"}</label>
            </div>
        );
    }
}
