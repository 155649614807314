import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import TermsConditions  from './TermsConditions';

export default class TermsConditionsModal extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { active_site, terms_conditions, translator } = this.props;
        
        return (
            <div id="termsConditionsModal" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title font-weight-bold">{translator.translate('global.terms_conditions')}</h3>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true"><i className="fa fa-times"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-lg-9 vh-65">
                                    <div className="card border rounded-0 grey lighten-4 mb-3 overflow-y-auto h-100">
                                        <div className="card-body card-well">
                                            <TermsConditions 
                                                active_site={active_site}
                                                company_terms={terms_conditions.full.custom}
                                                supplier_terms={terms_conditions.full.gateway}
                                                translator={translator}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 d-none d-lg-block vh-65 overflow-y-auto">
                                    {
                                        !_.isNull(terms_conditions.full.custom) &&
                                        <div className="mb-4">
                                            <h4 className="font-weight-bold">Company Terms</h4>
                                            <ul className="list-unstyled pl-2">
                                                {
                                                    terms_conditions.full.custom.sections.map((section, key) => {

                                                        return (
                                                            <li key={key} className="mb-1">
                                                                <a href={"#companyTermsSection_" + key}><span className="font-smaller four-text">{section.title}</span></a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }

                                    {
                                        !_.isNull(terms_conditions.full.gateway) &&
                                        <div>
                                            <h4 className="font-weight-bold">{translator.translate('global.supplier_terms')}</h4>
                                            <ul className="list-unstyled pl-2">
                                                {
                                                    terms_conditions.full.gateway.sections.map((section, key) => {

                                                        return (
                                                            <li key={key} className="mb-1">
                                                                <a href={"#supplierTermsSection_" + key}><span className="font-smaller four-text">{section.title}</span></a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal">{translator.translate('global.close')}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
