import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class InputText extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    <input 
                        
                        name={this.props.name} 
                        defaultValue={this.props.defaultValue} 
                        type="text" 
                        className={this.props.className ? this.props.className : "form-control"}
                        placeholder={this.props.placeholder} 
                        disabled={this.props.disabled || false}
                        onChange={this.props.onChange ? this.props.onChange : undefined}
                        {...this.props}
                    />
                </div>
                );
    }
}
