import { Helpers } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TimeAgo extends Component
{
    render()
    {
        return (Helpers.timeFromNow(this.props.dateTime));
    }
}
