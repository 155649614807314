//import { API, Helpers, Router, Translator } from '../Helpers';
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class RefundsPopover extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
        };
        
        this.togglePopover = this.togglePopover.bind(this);
    }
    
    togglePopover()
    {
        this.setState({
           popoverOpen: !this.state.popoverOpen 
        });
    }
    
    render()
    {
        const { id, refunds } = this.props;
        
        return (
            <div>
                <a
                    tabIndex="0"
                    className="text-muted btn btn-link p-0"
                    id={"Popover-" + id}
                >
                    <span className="font-small">{refunds.length} transactions</span>
                </a>
                <Popover
                    placement={'top'}
                    isOpen={this.state.popoverOpen}
                    target={"Popover-" + id}
                    toggle={this.togglePopover}
                    trigger={'click'}
                >
                    <PopoverBody className="popover-body p-3">
                        {
                            refunds.map((refund, key) => {

                                return (
                                    <div key={key} className={(key + 1) < refunds.length ? "mb-3": ""}>
                                        <div className="font-weight-bold">{refund.amount}</div>
                                        <div className="text-muted font-smaller">Transaction ID: {refund.transactionId}</div>
                                    </div>
                                )
                            })
                        }
                    </PopoverBody>
                </Popover>
            </div>
        );
    }
}
