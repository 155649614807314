export default function Router(routes) {
        
    var router = {
        url: function(routeName, params)
        {
            let url;
            let route = _.find(routes, function(_route) { return _route.name == routeName; });
            
            if (route)
            {
                url = route.url;
                
                if (params)
                {
                    _.forEach(params, (param, key) => {
                        
                        let routeParameter = "{" + key + "}";
                        
                        if (url.includes(routeParameter))
                        {
                            url = _.replace(url, routeParameter, param);
                            
                            delete params[key];
                        }
                    });
                    
                    if (!_.isEmpty(params))
                    {
                        url += "?" + $.param(params);
                    }
                }
            }
            
            let protocol = URI().protocol();
            
            if (!_.includes(url, protocol))
            {
                url = protocol + '://' + url;
            }
            
            //console.log(url);
            
            return url;
        }
    };

    return router;
}
