import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TableColumns extends Component
{
    render()
    {
        const { broker_references } = this.props;

        return (
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Roles</th>
                <th className="text-center">Active</th>
                <th scope="col">Last login</th>
                <th scope="col"></th>
            </tr>
        );
    }
}
