import { API } from '../Helpers';
import InputHidden from './InputHidden';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class MultiSelect extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            options: this.props.options,
            value: this.props.defaultValue ? this.props.defaultValue : []
        };
        
        this.onChange = this.onChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.setOptions = this.setOptions.bind(this);
    }
    
    componentDidMount()
    {
        console.log("MultiSelect componentDidMount");
        
        this.initialize();
        
        if (_.isEmpty(this.state.options))
        {
            this.getOptions();
        }
    }
    
    componentWillUnmount(){
        
        $('#' + this.props.id).material_select('destroy');
    }
    
    onChange(event)
    {
        let value = $(event.target).val();
        
        console.log('onChange', value);
        
        this.setState({
            value: value
        }, () => 
        {
            if (this.props.onChange)
            {
                this.props.onChange(value);
            }
        });
    }
    
    getOptions()
    {
        let url = this.props.env.API_URL + this.props.endpoint;
        
        //console.log("getOptions", url);
        
        let onResponse = {
            success: (response) => this.setOptions(response),
            error: err => 
            {
                console.log('err=>', err);
            },
            complete: () => 
            {
                console.log('complete=>');
            }
        }
        
        API.get(onResponse, url, this.props.token);
    }
    
    initialize()
    {
        $(document).ready(() => 
        {
            console.log('ready');
            
            if (this.props.parentModal)
            {
                $('#' + this.props.parentModal).on('shown.bs.modal', () => 
                {
                    console.log('initialize material_select');
                    
                    $('#' + this.props.id).material_select();
                });
            }
            else
            {
                console.log('initialize material_select');
                
                $('#' + this.props.id).material_select();
            }
        });
    }
    
    setOptions(data)
    {
        //console.log('setOptions', data);
        
        this.setState({
            options: data
        });
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    {
                        !_.isEmpty(this.state.options) &&
                        <select 
                            id={this.props.id} 
                            className="mdb-select multi-select md-form" 
                            multiple={true}
                            onChange={this.onChange}
                            value={this.state.value}
                        >
                            {
                                this.props.placeholder &&
                                <option disabled>{this.props.placeholder}</option>
                            }

                            {
                                !_.isEmpty(this.state.options.data) ?
                                this.state.options.data.map((option, key) =>
                                    <option key={key} value={option[this.props.idfield]}>{option[this.props.textfield]}</option>
                                ) :
                                
                                this.state.options.map((option, key) =>
                                    <option key={key} value={option[this.props.idfield]}>{option[this.props.textfield]}</option>
                                )
                            }   
                        </select>
                    }
                </div>
                );
    }
}
