import { Helpers } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

let isReactJSEnabled = false;

export default class Pagination extends Component {
    constructor(props) {
        super(props);

        isReactJSEnabled = this.props.onClick ? true : false;

        this.state = {
            page: this.props.currentPage,
            pageSize: this.props.pageSize,
        };

        this.urlParams = this.urlParams.bind(this);
        this.onPageSelect = this.onPageSelect.bind(this);
        this.onPageJumpToPage = this.onPageJumpToPage.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.paginate = this.paginate.bind(this);
    }

    urlParams(page, pageSize = this.props.pageSize) {
        let params = _.omit(this.props.request, ['page', 'page_size']);

        return _.assign({ page: page, page_size: pageSize }, params);
    }

    onPageSelect(selectedPage = null, pageSize = null) {

        const selection = {
            page: selectedPage || this.props.currentPage,
            pageSize: pageSize || this.state.pageSize
        }

        this.setState({
            ...selection
        }, () => {
            if (this.props.onClick) {

                if (this.props.returnPageSize)
                    this.props.onClick(selection, -1);
                else
                    this.props.onClick(selectedPage);
            }
        });

    }

    onPageJumpToPage(pageLinks) {


        const lastIndex = pageLinks.pages[pageLinks.pages.length - 1];
        const indexJump = lastIndex + (pageLinks.pages.length - 1);

        if (indexJump < pageLinks.totalPages)
            return indexJump;
        else if (!pageLinks.pages.includes(pageLinks.totalPages))
            return pageLinks.totalPages;
        else
            return null;

    }

    onPageSizeChange(event) {

        if (isReactJSEnabled)
            this.onPageSelect(1, event.target.value);
        else
            window.location = Helpers.url(this.props.baseUrl, this.urlParams(1, event.target.value));

    }

    paginate(totalItems = 0, currentPage = 1, pageSize = this.props.pageSize, maxPages = this.props.maxPages) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage, endPage;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    render() {
        const { baseUrl, request, route, router } = this.props;

        const pageLinks = this.paginate(this.props.total, this.props.currentPage, this.props.pageSize);

        const previousPage = pageLinks.currentPage > 1 ? pageLinks.currentPage - 1 : null;
        const nextPage = pageLinks.currentPage < pageLinks.totalPages ? pageLinks.currentPage + 1 : null;

        const to = pageLinks.endIndex + 1;
        const from = pageLinks.startIndex + 1;

        const pageIndex = (
            <div className="">
                <span className="font-weight-bold">
                    {from}
                </span>
                {
                    to > 1 &&
                    <span className="font-weight-bold"> — {to}</span>
                }
                <span className="text-muted"> of {numeral(this.props.total).format('0,0')}</span>
            </div>
        );


        return (
            <React.Fragment>
                <div>
                    <ul className="list-inline d-flex align-items-center text-nowrap mb-0">
                        {
                            isReactJSEnabled &&
                            <React.Fragment>
                                <input name={'page'} defaultValue={this.state.page} type="hidden" />
                                <input name={'page_size'} defaultValue={this.state.pageSize} type="hidden" />
                            </React.Fragment>
                        }

                        {
                            this.props.showTotal &&
                            <li className="list-inline-item mr-2 text-nowrap d-none d-md-block">
                                {pageIndex}
                            </li>
                        }

                        <li className="list-inline-item p-0">
                            {
                                isReactJSEnabled ?
                                    <button type={'submit'} className={pageLinks.currentPage !== 1 ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"} onClick={() => this.onPageSelect(1)}>
                                        <i className="fa fa-fw fa-angle-double-left fa-1-5x"></i>
                                    </button>
                                    :
                                    <a
                                        className={pageLinks.currentPage !== 1 ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"}
                                        href={Helpers.url(baseUrl, this.urlParams(1))}
                                    >
                                        <i className="fa fa-fw fa-angle-double-left fa-1-5x"></i>
                                    </a>
                            }
                        </li>

                        <li className="list-inline-item p-0">
                            {
                                isReactJSEnabled ?
                                    <button type={'submit'} className={previousPage ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"} onClick={() => this.onPageSelect(previousPage)}>
                                        <i className="fa fa-fw fa-angle-left fa-1-5x"></i>
                                    </button>
                                    :
                                    <a
                                        className={previousPage ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"}
                                        href={Helpers.url(baseUrl, this.urlParams(previousPage))}
                                    >
                                        <i className="fa fa-fw fa-angle-left fa-1-5x"></i>
                                    </a>
                            }
                        </li>

                        <li className="list-inline-item d-flex">
                            {
                                pageLinks.pages.map(page => {

                                    if (isReactJSEnabled) {
                                        return (
                                            <button type={'submit'} key={page} className={pageLinks.currentPage !== page ? "btn btn-flat" : "btn btn-flat disabled text-muted"} onClick={() => this.onPageSelect(page)}>
                                                {page}
                                            </button>
                                        )
                                    } else {
                                        return (
                                            <a
                                                key={page}
                                                className={pageLinks.currentPage !== page ? "btn btn-flat" : "btn btn-flat disabled text-muted"}
                                                href={Helpers.url(baseUrl, this.urlParams(page))}>{page}</a>

                                        )
                                    }

                                })
                            }

                            {
                                this.onPageJumpToPage(pageLinks) !== null &&
                                <React.Fragment>
                                    <button type={'submit'} key={'...'} className={"btn btn-flat disabled p-0 d-none d-md-block"}>{'...'}</button>
                                    {
                                        isReactJSEnabled ?
                                            <button type={'submit'} key={pageLinks.totalPages} className={"btn btn-flat d-none d-md-block"} onClick={() => this.onPageSelect(this.onPageJumpToPage(pageLinks))}>
                                                {this.onPageJumpToPage(pageLinks)}
                                            </button>
                                            :
                                            <a
                                                key={pageLinks.totalPages}
                                                className={"btn btn-flat d-none d-md-block"}
                                                href={Helpers.url(baseUrl, this.urlParams(this.onPageJumpToPage(pageLinks)))}>{this.onPageJumpToPage(pageLinks)}</a>
                                    }
                                </React.Fragment>

                            }
                        </li>

                        <li className="list-inline-item p-0">
                            {
                                isReactJSEnabled ?
                                    <button type={'submit'} className={nextPage ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"} onClick={() => this.onPageSelect(nextPage)}>
                                        <i className="fa fa-fw fa-angle-right fa-1-5x"></i>
                                    </button>
                                    :
                                    <a
                                        className={nextPage ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"}
                                        href={Helpers.url(baseUrl, this.urlParams(nextPage))}
                                    >
                                        <i className="fa fa-fw fa-angle-right fa-1-5x"></i>
                                    </a>
                            }
                        </li>

                        <li className="list-inline-item mr-0">
                            {
                                isReactJSEnabled ?
                                    <button type={'submit'} className={pageLinks.currentPage !== pageLinks.totalPages ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"} onClick={() => this.onPageSelect(pageLinks.totalPages)}>
                                        <i className="fa fa-fw fa-angle-double-right fa-1-5x"></i>
                                    </button>
                                    :
                                    <a
                                        className={pageLinks.currentPage !== pageLinks.totalPages ? "btn btn-flat p-0" : "btn btn-flat disabled p-0 text-muted"}
                                        href={Helpers.url(baseUrl, this.urlParams(pageLinks.totalPages))}
                                    >
                                        <i className="fa fa-fw fa-angle-double-right fa-1-5x"></i>
                                    </a>
                            }
                        </li>

                        <li className="list-inline-item d-none d-md-block ml-2">
                            {
                                <select
                                    name={'pageSizeChange'}
                                    className={"browser-default custom-select"}
                                    defaultValue={this.props.pageSize}
                                    onChange={this.onPageSizeChange}>
                                    {
                                        this.props.pageSizeOptions.map((option, key) =>
                                            <option key={key} value={option}>{option}</option>
                                        )
                                    }
                                </select>
                            }
                        </li>

                        {
                            this.props.showHelp &&
                            <li className="list-inline-item d-none d-md-block">
                                <div className="dropdown">
                                    <button className="btn btn-flat dropdown-toggle no-caret" type="button" data-toggle="dropdown">
                                        <i className="fa fa-fw fa-cog"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" href="#">Settings</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item"
                                           data-toggle="modal"
                                           data-target="#feedbackModal"><i className="fa fa-lg fa-fw fa-bug mr-2"></i>Report a problem</a>
                                        <a className="dropdown-item" href={router.url('support.home')} target="_blank"><i className="far fa-lg fa-fw fa-question-circle mr-2"></i>Help</a>
                                    </div>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
                <div className='col-12 d-block d-md-none d-lg-none text-center'>
                    {pageIndex}
                </div>
            </React.Fragment>
        );
    }
}

Pagination.defaultProps = {
    returnPageSize: false,
    pageSizeOptions: [25, 50, 100, 200],
    showHelp: false,
    maxPages: 4,
    currentPage: 1,
    pageSize: 25,
    total: 0,
    baseUrl: '',
    showTotal: true
}

