export default function Translator(translations) {

    var translator = {
        translate: function(key, params)
        {
            const translation = _.find(translations.data, (_translation) => 
            { 
                const pieces = key.split('.');
                
                return (_translation.group == pieces[0] && _translation.key == pieces[1]); 
            });
        
            if (!_.isEmpty(translation))
            {
                let text = translation.text;
                
                if (!_.isEmpty(params))
                {
                    _.forEach(params, (param, key) => {
                        
                        if (text.includes(key))
                        {
                            text = _.replace(text, key, param);
                            
                            delete params[key];
                        }
                    });
                }
                
                return text;
            }

            return key;
        }
    };

    return translator;
}
