import { Helpers } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class FormattedDateString extends Component
{
    render()
    {
        return (Helpers.toFormattedDateString(this.props.dateTime));
    }
}
