import { API, Helpers, Router, Translator } from '../Helpers';
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { Loader } from '../Core';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import RefundsPopover from './RefundsPopover';

export default class Transactions extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            show_loader: false,
            broker_references: [],
            transactions: []
        };

        this.fetchTransactions = this.fetchTransactions.bind(this);
        this.showTransactionsModal = this.showTransactionsModal.bind(this);
    }

    componentDidMount()
    {
        if (!_.isEmpty(this.props.booking.brokerReference))
        {
            this.fetchTransactions();
        }

        $("#transactionsModal").on('hidden.bs.modal', (event) =>
        {
            this.setState({
                transactions: []
            });
        });
    }

    fetchTransactions()
    {
        let onResponse = {
            success: (response) =>
            {
                //console.log('fetchTransactions Response', response);

                $(() =>
                {
                    $('[data-toggle="popover"]').popover();
                });

                this.setState({
                    show_loader: false,
                    broker_references: response
                });
            },
            error: error =>
            {
                this.setState({
                    errors: error,
                    show_loader: false,
                    broker_references: []
                });
            }
        }

        this.setState({
            errors: null,
            show_loader: true,
            broker_references: [],
        },() =>
        {
            API.get(onResponse, this.props.router.url('bookings.broker_references.index', {booking: this.props.booking.reservNum}));
        });
    }

    showTransactionsModal(transactions)
    {
        this.setState({
            transactions: transactions
        }, () =>
        {
            $("#transactionsModal").modal('show');
        });
    }

    render()
    {
        const id = uniqid();
        const { booking, env, router } = this.props;

        return (
            <div>
                <h3 className="font-weight-bold"><i className="far fa-credit-card mr-2"></i>Transactions</h3>

                <hr className="my-4" />

                {
                    this.state.show_loader &&
                    <Loader />
                }

                {
                    _.isEmpty(this.state.broker_references) ?
                    <div className="py-5">
                        <div className="text-center"><h4>No transactions found.</h4></div>
                    </div> :
                    <div className="mb-5">
                        <div className="card border rounded-0">
                            <div className="card-body p-0">
                                <table className="table w-100 mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="font-weight-bold">Broker Reference ID</th>
                                            <th scope="col" className="font-weight-bold">Type</th>
                                            <th scope="col" className="font-weight-bold">Status</th>
                                            <th scope="col" className="font-weight-bold">Amount</th>
                                            <th scope="col" className="font-weight-bold">Transaction ID</th>
                                            <th scope="col" className="font-weight-bold">Date</th>
                                            <th scope="col" className="font-weight-bold">Payment Provider</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.broker_references[0].cancelAmount > 0 &&
                                            <tr>
                                                <td className="align-middle">
                                                    <a className="btn btn-link p-0 text-primary" target="_blank" href={router.url('broker_references.show', {broker_reference: this.state.broker_references[0].id})}>{this.state.broker_references[0].id}</a>
                                                </td>
                                                <td className="align-middle">
                                                    <div>Refund</div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="card card-body border p-2" style={{ width:'150px' }}>
                                                        <div>
                                                            {
                                                                !_.isEmpty(this.state.broker_references[0].cancelRefunds) &&
                                                                <div>
                                                                    <i className="fa fa-fw fa-circle text-muted mr-2"></i>
                                                                    <span className="font-weight-bold">Refunded</span>
                                                                </div>
                                                            }

                                                            {
                                                                _.isEmpty(this.state.broker_references[0].cancelRefunds) &&
                                                                <div>
                                                                    <i className="fa fa-fw fa-circle text-warning mr-2"></i>
                                                                    <span className="font-weight-bold">Pending refund</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>

                                                <td className="align-middle">
                                                    <div className="text-muted">{Helpers.formatCurrency(this.state.broker_references[0].cancelAmount, this.state.broker_references[0].currency, env.APP_LOCALE)}</div>
                                                </td>
                                                <td className="align-middle">
                                                    {
                                                        !_.isEmpty(this.state.broker_references[0].cancelRefunds) &&
                                                        <div>
                                                            {
                                                                this.state.broker_references[0].cancelRefunds.length == 1 &&
                                                                <div>{this.state.broker_references[0].cancelRefunds[0].transactionId}</div>
                                                            }

                                                            {
                                                                this.state.broker_references[0].cancelRefunds.length > 1 &&
                                                                <button
                                                                className="btn btn-link p-0"
                                                                onClick={() => this.showTransactionsModal(this.state.broker_references[0].cancelRefunds)}>
                                                                    {this.state.broker_references[0].cancelRefunds.length} transactions
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                </td>
                                                <td className="align-middle">{Helpers.toDateString(booking.modified_date_time)} {Helpers.toTimeString(booking.modified_date_time)}</td>
                                                <td className="align-middle">{this.state.broker_references[0].paymentComponent}</td>

                                                <td className="align-middle">
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-flat dropdown-toggle float-right no-caret"
                                                            type="button" data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <i className="fa fa-ellipsis-v"></i>
                                                        </button>
                                                        <div
                                                            className="dropdown-menu dropdown-menu-right">
                                                            <a
                                                                target="_blank"
                                                                className="dropdown-item"
                                                                href={router.url('broker_references.show', {broker_reference: this.state.broker_references[0].id})}>
                                                                View details
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                        {
                                            this.state.broker_references.map((broker_reference, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <td className="align-middle">
                                                            <a className="btn btn-link p-0 text-primary" target="_blank" href={router.url('broker_references.show', {broker_reference: broker_reference.id})}>{broker_reference.id}</a>
                                                        </td>
                                                        <td className="align-middle">
                                                            {
                                                                broker_reference.authAmount > 0 &&
                                                                <div>
                                                                    Payment
                                                                </div>
                                                            }

                                                            {
                                                                broker_reference.refundAmount > 0 &&
                                                                <div>Refund</div>
                                                            }
                                                        </td>

                                                        <td className="align-middle">
                                                            <div className="card card-body border p-2" style={{ width:'150px' }}>
                                                                {
                                                                    broker_reference.authAmount > 0 &&
                                                                    <div>
                                                                        {
                                                                            broker_reference.captured &&
                                                                            <div>
                                                                                <i className="fa fa-fw fa-circle text-success mr-2"></i>
                                                                                <span className="font-weight-bold">Captured</span>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            !broker_reference.captured &&
                                                                            <div>
                                                                                <i className="fa fa-fw fa-circle text-warning mr-2"></i>
                                                                                <span className="font-weight-bold">Pending capture</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    broker_reference.refundAmount > 0 &&
                                                                    <div>
                                                                        {
                                                                            broker_reference.refunded &&
                                                                            <div>
                                                                                <i className="fa fa-fw fa-circle text-muted mr-2"></i>
                                                                                <span className="font-weight-bold">Refunded</span>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            !broker_reference.refunded &&
                                                                            <div>
                                                                                <i className="fa fa-fw fa-circle text-warning mr-2"></i>
                                                                                <span className="font-weight-bold">Pending refund</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>

                                                        <td className="align-middle">
                                                            {
                                                                broker_reference.authAmount > 0 &&
                                                                <div className="text-muted">{Helpers.formatCurrency(broker_reference.authAmount, broker_reference.currency, env.APP_LOCALE)}</div>
                                                            }

                                                            {
                                                                broker_reference.refundAmount > 0 &&
                                                                <div className="text-muted">{Helpers.formatCurrency(broker_reference.refundAmount, broker_reference.currency, env.APP_LOCALE)}</div>
                                                            }
                                                        </td>
                                                        <td className="align-middle">
                                                            {
                                                                !_.isEmpty(broker_reference.transactionId) &&
                                                                <div>{broker_reference.transactionId}</div>
                                                            }

                                                            {
                                                                _.isEmpty(broker_reference.transactionId) &&
                                                                <div>
                                                                    {
                                                                        !_.isEmpty(broker_reference.refunds) &&
                                                                        <div>
                                                                            {
                                                                                broker_reference.refunds.length == 1 &&
                                                                                <div>{broker_reference.refunds[0].transactionId}</div>
                                                                            }

                                                                            {
                                                                                broker_reference.refunds.length > 1 &&
                                                                                <button
                                                                                    className="btn btn-link p-0"
                                                                                    onClick={() => this.showTransactionsModal(broker_reference.refunds)}>2 transactions
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </td>
                                                        <td className="align-middle">{Helpers.toDateString(broker_reference.created)} {Helpers.toTimeString(broker_reference.created)}</td>
                                                        <td className="align-middle">{broker_reference.paymentComponent}</td>

                                                        <td className="align-middle">
                                                            <div className="dropdown">
                                                                <button
                                                                    className="btn btn-flat dropdown-toggle float-right no-caret"
                                                                    type="button" data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <i className="fa fa-ellipsis-v"></i>
                                                                </button>
                                                                <div
                                                                    className="dropdown-menu dropdown-menu-right">
                                                                    <a
                                                                        target="_blank"
                                                                        className="dropdown-item"
                                                                        href={router.url('broker_references.show', {broker_reference: broker_reference.id})}>
                                                                        View details
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                <div className="modal fade" id="transactionsModal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="font-weight-bold mb-0">Transactions</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body p-4">
                                <table className="table table-bordered mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Transaction ID</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.transactions.map((transaction, key) => {

                                                return (
                                                    <tr key={key}>
                                                        <th scope="row">{transaction.transactionId}</th>
                                                        <td>{Helpers.formatCurrency(transaction.amount, booking.currency, env.APP_LOCALE)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
