import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class CustomerInformation extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { booking, router } = this.props;
        
        return (
            <div className="card h-100 border rounded-0">
                <div className="card-header white p-4">
                    <h4 className="mb-0 font-weight-bold"><i className="far fa-fw fa-lg fa-user mr-2"></i>Customer information</h4>
                </div>
                <div className="card-body p-4">
                    <table className="w-100 mb-4">
                        <tbody>
                            <tr>
                                <td className="w-50">Driver's age</td>
                                <td className="w-50"><strong>{booking.driver_age}</strong></td>
                            </tr>
                            <tr>
                                <td className="align-middle">Residence</td>
                                <td className="align-middle">
                                    <div className="f32" >
                                        <span className={"flag " + _.lowerCase(booking.residence_country)}></span> 
                                    </div>
                                </td>
                            </tr>
                        <tr>
                            <td>Language</td>
                            <td>--</td>
                        </tr>
                        </tbody>
                    </table>

                    <table className="w-100 mb-4">
                        <tbody>
                            <tr>
                                <td className="w-50">Customer name</td>
                                <td className="w-50"><a href="#" target="_blank" className="btn-link text-primary font-weight-bold">{booking.customer.title} {booking.customer.firstName} {booking.customer.lastName}</a></td>
                            </tr>
                            <tr>
                                <td>E-mail</td>
                                <td>{booking.customer.email}</td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{booking.customer.phone}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>
                                    <span className="text-muted">ZIP:</span> {booking.customer.postalCode}
                                </td>
                            </tr>
                            <tr>
                                <td>Flight number</td>
                                <td>{booking.flightNumber}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className="w-50">Payment method</td>
                                <td className="w-50">--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
