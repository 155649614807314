import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Datepicker extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            uniqid: 'colorpicker_' + uniqid(),
            color: 'rgb(255, 255, 255)'
        };
    }
    
    componentDidMount()
    {
        $('#' + this.state.uniqid).colorpicker(this.props.options || {}).on('changeColor.colorpicker', event => {
            this.props.onChange(event.target.value);
        });
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    <div className="input-group colorpicker-component colorpicker-element">
                        {
                            this.props.prepend &&
                            <div className="input-group-prepend">
                                <span className="input-group-text">{this.props.prepend}</span>
                            </div>
                        }
                        
                        <input 
                            id={this.state.uniqid}
                            name={this.props.name} 
                            defaultValue={this.props.defaultValue?this.props.defaultValue:this.state.color} 
                            type="text" 
                            className="form-control"
                            placeholder={this.props.placeholder} 
                            data-toggle="colorpicker" 
                        />

                        <div className="input-group-append">
                            <span className="input-group-text">
                                <span className="input-group-addon p-0"><i style={{backgroundColor: this.state.color}}></i></span>
                            </span>
                        </div>
                        
                    </div>
                </div>
                );
    }
}
