import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Loader extends Component
{
    render()
    {
        return (
                <div className="p-3">
                    <div className="d-flex justify-content-center" style={{height: "50px"}}>
                        <div className="spinner-border text-primary mr-3" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                );
    }
}
