import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TableColumns extends Component
{
    render()
    {
        const { broker_references } = this.props;

        return (
            <tr>
                <th className="no-wrap" width="55">
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="selectAll"
                            onChange={this.props.toggleAllBrokerReferences}
                            checked={this.props.selected_broker_references.length == broker_references.meta.pagination.count}
                        />
                        <label className="custom-control-label" htmlFor="selectAll">All</label>
                    </div>
                </th>
                <th className="no-wrap">Status</th>
                <th className="no-wrap">Broker Reference ID</th>
                <th className="no-wrap">Transaction ID</th>
                <th>Customer</th>
                <th className="no-wrap">Booking Number</th>
                <th className="no-wrap text-center">Captured</th>
                <th>Time</th>
                <th></th>
            </tr>
        );
    }
}
