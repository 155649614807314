import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Avatar extends Component
{
    render()
    {
        const src = this.props.src ? this.props.src : "https://loremflickr.com/600/600";
        
        return (
                <div className={"avatar " + this.props.className}>
                    <img className="img-fluid" src={src} />
                </div>
                );
    }
}
