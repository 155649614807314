import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import {InputHidden, Pagination, Select, TextArea} from "../Core";
import TableColumns from "./TableColumns";

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_broker_references: []
        };

        this.findBrokerReferenceStatus = this.findBrokerReferenceStatus.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.selectBrokerReference = this.selectBrokerReference.bind(this);
        this.showConfirmVoidTransactionModal = this.showConfirmVoidTransactionModal.bind(this);
        this.showConfirmVoidTransactionsModal = this.showConfirmVoidTransactionsModal.bind(this);
        this.showVoidTransactionLoader = this.showVoidTransactionLoader.bind(this);
        this.showVoidTransactionsLoader = this.showVoidTransactionsLoader.bind(this);
        this.toggleAllBrokerReferences = this.toggleAllBrokerReferences.bind(this);
        this.toggleBrokerReference = this.toggleBrokerReference.bind(this);
        this.toggleConfirmVoidTransaction = this.toggleConfirmVoidTransaction.bind(this);
        this.toggleConfirmVoidTransactions = this.toggleConfirmVoidTransactions.bind(this);
        this.unselectBrokerReference = this.unselectBrokerReference.bind(this);
    }

    componentDidMount()
    {
        $('#confirmVoidTransactionModal').on('hidden.bs.modal', (event) =>
        {
            this.setState({
                broker_reference: {}
            });
        })
    }

    findBrokerReferenceStatus(status_id)
    {
        let broker_reference_status = _.find(this.props.data.broker_reference_statuses, (broker_reference_status) => { return status_id == broker_reference_status.id; });

        if (!_.isEmpty(broker_reference_status))
        {
            return broker_reference_status;
        }
    }

    isChecked(broker_reference)
    {
        let index = _.findIndex(this.state.selected_broker_references, (_broker_reference) =>
        {
            return _broker_reference == broker_reference;
        });

        if (index > -1)
        {
            return true;
        }

        return false
    }

    selectBrokerReference(broker_reference)
    {
        // console.log("selectBrokerReference", broker_reference);

        let selected_broker_references = [...this.state.selected_broker_references];

        selected_broker_references.push(broker_reference);

        this.setState({
            selected_broker_references: selected_broker_references
        });
    }

    showConfirmVoidTransactionModal(broker_reference)
    {
        this.setState({
           broker_reference: broker_reference
        }, () =>
        {
            $('#confirmVoidTransactionModal').modal('show');
        });
    }

    showConfirmVoidTransactionsModal()
    {
        $('#confirmVoidTransactionsModal').modal('show');
    }

    showVoidTransactionLoader()
    {
        console.log("showVoidTransactionLoader");

        this.setState({
            show_void_transaction_loader: true
        });
    }

    showVoidTransactionsLoader()
    {
        console.log("showVoidTransactionsLoader");

        this.setState({
            show_void_transactions_loader: true
        });
    }

    toggleAllBrokerReferences()
    {
        let selected_broker_references = [...this.state.selected_broker_references];

        //console.log(selected_broker_references.length);
        //console.log(this.props.data.broker_references.meta.count);

        if (selected_broker_references.length < this.props.data.broker_references.meta.pagination.count)
        {
            selected_broker_references = _.map(this.props.data.broker_references.data, 'brokerReference'); //this.props.data.broker_references.data;
        }
        else
        {
            selected_broker_references = [];
        }

        this.setState({
            selected_broker_references: selected_broker_references
        });
    }

    toggleBrokerReference(event)
    {
        // console.log(event.target.checked, event.target.value);

        let value = event.target.value;

        if (event.target.checked)
        {
            this.selectBrokerReference(value);
        }
        else
        {
            this.unselectBrokerReference(value);
        }
    }

    toggleConfirmVoidTransaction()
    {
        this.setState({
            confirm_void_transaction: !this.state.confirm_void_transaction
        });
    }

    toggleConfirmVoidTransactions()
    {
        this.setState({
            confirm_void_transactions: !this.state.confirm_void_transactions
        });
    }

    unselectBrokerReference(broker_reference)
    {
        // console.log("unselectBrokerReference", broker_reference);

        let selected_broker_references = this.state.selected_broker_references.filter((_broker_reference) =>
        {
            return _broker_reference !== broker_reference;
        });

        this.setState({
            selected_broker_references: selected_broker_references
        });
    }

    render() {

        const { active_site, alert, broker_reference_statuses, csrf_token, env, payment_providers, broker_references, request, routes, user } = this.props.data;
        const router = Router(routes);

        return (
            <div>
                <div className="container-fluid">
                    {
                        !_.isEmpty(alert) &&
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className={"alert mb-3 " + alert.type} role="alert">
                                    <div>
                                        <h4 className="font-weight-bold"><i className={"fa-lg mr-2 " + alert.icon}></i>{alert.heading}</h4>
                                        <div>{alert.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mb-3">
                        <div className="col-12">
                            <h2>Broker References</h2>
                        </div>
                    </div>

                    <div className="mb-4">
                        <form action={router.url('broker_references.index')} method="GET">
                            <div className="row form-row mb-3">
                                <div className="col-3">
                                    <label className="">Broker Reference ID</label>
                                    <input name="broker_reference" defaultValue={request.broker_reference ?? null} type="text" id="broker_reference" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label className="">Transaction ID</label>
                                    <input name="transaction_id" defaultValue={request.transaction_id ?? null} type="text" id="transaction_id" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label htmlFor="status" className="">Status</label>

                                    <select
                                        name="status_id"
                                        className="browser-default custom-select"
                                        defaultValue={request.status_id ?? null}
                                    >
                                        <option value="">Choose an option</option>

                                        {
                                            broker_reference_statuses.map((broker_reference_status, key) =>
                                                <option key={key} value={broker_reference_status.id}>{broker_reference_status.name}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                <div className="col-3">
                                    <label htmlFor="payment_provider" className="">Payment Provider</label>

                                    <select
                                        name="payment_provider"
                                        className="browser-default custom-select"
                                        defaultValue={request.payment_provider ?? null}
                                    >
                                        <option value="">Choose an option</option>

                                        {
                                            payment_providers.data.map((payment_provider, key) =>
                                                <option key={key} value={payment_provider.id}>{payment_provider.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="row form-row mb-3">
                                <div className="col-3">
                                    <label className="">First Name</label>
                                    <input name="first_name" defaultValue={request.first_name ?? null} type="text" id="first_name" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label className="">Last Name</label>
                                    <input name="last_name" defaultValue={request.last_name ?? null} type="text" id="last_name" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label className="">Email</label>
                                    <input name="email" defaultValue={request.email ?? null} type="text" id="email" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label className="">Shopper IP Address</label>
                                    <input name="shopper_ip" defaultValue={request.shopper_ip ?? null} type="text" id="shopper_ip" className="form-control" />
                                </div>
                            </div>

                            <div className="row form-row mb-3">
                                <div className="col-2">
                                    <label className="">Booking Number</label>
                                    <input name="booking_number" defaultValue={request.booking_number ?? null} type="text" id="booking_number" className="form-control" />
                                </div>

                                <div className="col-3">
                                    <label htmlFor="start_date" className="">Start Date</label>
                                    <div className="input-group">
                                        <input name="start_date"
                                           placeholder="choose a date"
                                           type="text"
                                           id="start_date"
                                           className="form-control datepicker"
                                           defaultValue={request.start_date ?? null}
                                        />
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="start_date"><i className="fa fa-calendar-alt"></i></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <label htmlFor="end_date" className="">End Date</label>
                                    <div className="input-group">
                                        <input
                                            name="end_date"
                                            placeholder="choose a date"
                                            type="text"
                                            id="end_date"
                                            className="form-control datepicker"
                                            defaultValue={request.end_date ?? null}
                                        />
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="end_date"><i className="fa fa-calendar-alt"></i></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2">
                                    <label className="invisible">Submit</label>
                                    <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                </div>
                                <div className="col-2">
                                    <label className="invisible">Reset</label>
                                    <a href={router.url('broker_references.index')} className="btn btn-secondary btn-block"><i className="fas fa-undo mr-2"></i>Reset</a>
                                </div>
                            </div>
                        </form>
                    </div>

                    {
                        broker_references.meta.pagination.count == 0 ?
                        <div className="text-center py-8">
                            <h4 className="font-weight-bold mb-3 text-muted">Use the options above to generate the report</h4>
                            <p className="text-muted">Start by selecting dates</p>
                        </div> :
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="card border mb-3">
                                    <div className="card-header white p-3">
                                        <div className="row">
                                            <div className="col-2 d-flex align-items-end">
                                                <ul className="list-inline mb-0 d-flex align-content-center">
                                                    <li className="list-inline-item">
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-outline-primary dropdown-toggle no-caret"
                                                                type="button"
                                                                data-toggle="dropdown"
                                                                disabled={_.isEmpty(this.state.selected_broker_references)}>
                                                                <i className="fas fa-lg fa-fw fa-file-invoice mr-1"></i><i
                                                                className="fas fa-fw fa-chevron-down"></i>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-primary">
                                                                <h6 className="dropdown-header px-2 font-weight-bold">ACTIONS</h6>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={this.showConfirmVoidTransactionsModal}
                                                                >
                                                                    Void transactions
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-10 d-flex justify-content-end">
                                                <div>
                                                    <Pagination
                                                        currentPage={this.props.data.broker_references.meta.pagination.current_page}
                                                        pageSize={this.props.data.broker_references.meta.pagination.per_page}
                                                        request={request}
                                                        router={router}
                                                        total={this.props.data.broker_references.meta.pagination.total}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table table-striped table-bordered mb-0">
                                                <thead>
                                                    <TableColumns
                                                        broker_references={broker_references}
                                                        selected_broker_references={this.state.selected_broker_references}
                                                        toggleAllBrokerReferences={this.toggleAllBrokerReferences}
                                                    />
                                                </thead>

                                                <tbody>
                                                {
                                                    broker_references.data.map((broker_reference, key) => {

                                                        let broker_reference_status = this.findBrokerReferenceStatus(broker_reference.statusId);

                                                        return (
                                                            <tr key={key}>
                                                                <td className="align-middle">
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id={"brokerReference" + key}
                                                                            defaultValue={broker_reference.id}
                                                                            onChange={this.toggleBrokerReference}
                                                                            checked={this.isChecked(broker_reference.id)}
                                                                        />
                                                                        <label className="custom-control-label" htmlFor={"brokerReference" + key}></label>
                                                                    </div>
                                                                </td>

                                                                <td className="align-middle no-wrap">
                                                            <span
                                                                className={"badge z-depth-0 p-2 " + "badge-" + broker_reference_status.color}>{broker_reference_status.name}</span>
                                                                </td>

                                                                <td className="align-middle">
                                                                    <a target="_blank" className="btn-link" href={router.url('broker_references.show', {broker_reference: broker_reference.id})}>
                                                                <span
                                                                    className="text-muted text-primary">{broker_reference.id}</ span>
                                                                    </a>
                                                                </td>

                                                                <td className="align-middle">
                                                                    {
                                                                        !_.isEmpty(broker_reference.transactionId) &&
                                                                        <div>
                                                                            <div
                                                                                className="font-weight-bold">{broker_reference.transactionId}</div>
                                                                            <div
                                                                                className="text-muted font-smaller">{broker_reference.paymentComponent}</div>
                                                                        </div>
                                                                    }
                                                                </td>

                                                                <td className="align-middle no-wrap">
                                                                    <div
                                                                        className="font-weight-bold">{broker_reference.firstName} {broker_reference.lastName}</div>
                                                                    <div
                                                                        className="text-muted font-smaller">{broker_reference.email}</div>
                                                                </td>

                                                                <td className="align-middle">
                                                                    <a className="btn-link" target="_blank"
                                                                       href={router.url('office.bookings.show', {reserv_num: broker_reference.bookingNumber})}>
                                                                <span
                                                                    className="text-muted text-primary">{broker_reference.bookingNumber}</span>
                                                                    </a>
                                                                </td>

                                                                <td className="align-middle no-wrap text-center">
                                                                    {
                                                                        broker_reference.captured == true &&
                                                                        <span>YES</span>
                                                                    }

                                                                    {
                                                                        !broker_reference.captured == true &&
                                                                        <span>NO</span>
                                                                    }
                                                                </td>

                                                                <td className="align-middle no-wrap">
                                                                    <div
                                                                        className="font-weight-bold">{Helpers.toDateString(broker_reference.created)}</div>
                                                                    <div
                                                                        className="text-muted font-smaller">{Helpers.toTimeString(broker_reference.created)}</div>
                                                                </td>

                                                                <td className="align-middle">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-flat dropdown-toggle float-right no-caret"
                                                                            type="button" data-toggle="dropdown"
                                                                            aria-haspopup="true"
                                                                            aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-v"></i>
                                                                        </button>
                                                                        <div
                                                                            className="dropdown-menu dropdown-menu-right">
                                                                            <a
                                                                                target="_blank"
                                                                                className="dropdown-item"
                                                                                href={router.url('broker_references.show', {broker_reference: broker_reference.id})}>
                                                                                View details
                                                                            </a>

                                                                            {
                                                                                !_.isEmpty(broker_reference.transactionId) &&
                                                                                <div>
                                                                                    <div
                                                                                        className="dropdown-divider"></div>

                                                                                    <button
                                                                                        className="dropdown-item"
                                                                                        onClick={() => this.showConfirmVoidTransactionModal(broker_reference)}>Void
                                                                                        transaction
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <Pagination
                                        currentPage={this.props.data.broker_references.meta.pagination.current_page}
                                        pageSize={this.props.data.broker_references.meta.pagination.per_page}
                                        request={request}
                                        router={router}
                                        total={this.props.data.broker_references.meta.pagination.total}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="modal fade" id="confirmVoidTransactionModal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 pb-0">
                                <button type="button " className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5 pb-5 pt-0">
                                <div>
                                    <div className="font-largest font-weight-bold mb-4">Void transaction</div>

                                    {
                                        !_.isEmpty(this.state.broker_reference) &&
                                        <div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <form
                                                        action={router.url('broker_references.void')}
                                                        method="POST"
                                                        onSubmit={this.showVoidTransactionLoader}
                                                    >
                                                        <InputHidden name="_token" defaultValue={csrf_token}/>
                                                        <InputHidden name="redirect_to" defaultValue={window.location.href}/>
                                                        <InputHidden name="broker_references[]" defaultValue={this.state.broker_reference.id}/>
                                                        <InputHidden name="user_id" defaultValue={user.user.id}/>

                                                        <p>This will void Transaction ID: <span className="font-weight-bold">{this.state.broker_reference.transactionId}</span></p>

                                                        <p className="mb-4">This CANNOT be undone!</p>

                                                        <div className="form-group">
                                                            <TextArea
                                                                name="comment"
                                                                label="Comment"
                                                                rows={3}
                                                            />
                                                        </div>

                                                        <div className="custom-control custom-checkbox mb-4">
                                                            <input
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                id="confirmReset"
                                                                onChange={this.toggleConfirmVoidTransaction}
                                                            />
                                                            <label className="custom-control-label" htmlFor="confirmReset">Yes, I would like to void this transaction.</label>
                                                        </div>

                                                        <div className="row form-row">
                                                            <div className="col-6">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-block btn-danger py-3 font-weight-bold"
                                                                    disabled={!this.state.confirm_void_transaction}>
                                                                    <ul className="list-inline mb-0 d-flex align-items-center justify-content-center">
                                                                        {
                                                                            this.state.show_void_transaction_loader &&
                                                                            <li className="list-inline-item mr-2">
                                                                                <div className="spinner-border spinner-border-sm" role="status"></div>
                                                                            </li>
                                                                        }

                                                                        <li className="list-inline-item">Void transaction</li>
                                                                    </ul>
                                                                </button>
                                                            </div>
                                                            <div className="col-6">
                                                                <button className="btn btn-block btn-outline-secondary py-3 font-weight-bold" data-dismiss="modal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="confirmVoidTransactionsModal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 pb-0">
                                <button type="button " className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5 pb-5 pt-0">
                                <div>
                                    <div className="font-largest font-weight-bold mb-4">Void transactions</div>

                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                <form
                                                    action={router.url('broker_references.void')}
                                                    method="POST"
                                                    onSubmit={this.showVoidTransactionsLoader}
                                                >
                                                    <InputHidden name="_token" defaultValue={csrf_token} />
                                                    <InputHidden name="redirect_to" defaultValue={window.location.href} />
                                                    <InputHidden name="user_id" defaultValue={user.user.id}/>

                                                    {
                                                        this.state.selected_broker_references.map((broker_reference, key) => {

                                                            return (
                                                                <InputHidden key={key} name="broker_references[]" defaultValue={broker_reference} />
                                                            )
                                                        })
                                                    }

                                                    <p className="mb-4">This will void <span className="font-weight-bold">{this.state.selected_broker_references.length}</span> transactions and CANNOT be undone!</p>

                                                    <div className="form-group">
                                                        <TextArea
                                                            name="comment"
                                                            label="Comment"
                                                            rows={3}
                                                        />
                                                    </div>

                                                    <div className="custom-control custom-checkbox mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="confirmReset"
                                                            defaultValue="1"
                                                            onChange={this.toggleConfirmVoidTransactions}
                                                        />
                                                        <label className="custom-control-label" htmlFor="confirmReset">Yes, I would like to void these transactions.</label>
                                                    </div>

                                                    <div className="row form-row">
                                                        <div className="col-6">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-block btn-danger py-3 font-weight-bold"
                                                                disabled={!this.state.confirm_void_transactions}>
                                                                <ul className="list-inline mb-0 d-flex align-items-center justify-content-center">
                                                                    {
                                                                        this.state.show_void_transactions_loader &&
                                                                        <li className="list-inline-item mr-2">
                                                                            <div className="spinner-border spinner-border-sm" role="status"></div>
                                                                        </li>
                                                                    }

                                                                    <li className="list-inline-item">Void transactions</li>
                                                                </ul>
                                                            </button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button className="btn btn-block btn-outline-secondary py-3 font-weight-bold" data-dismiss="modal">Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('brokerReferencesIndex')) {

    const element = document.getElementById('brokerReferencesIndex');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<ErrorBoundary><Index data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('brokerReferencesIndex'));
}
