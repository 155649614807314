import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Router} from '../Helpers';
import axios from 'axios';
import Dropzone from 'react-dropzone';

import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';

export default class MediaUploader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imageListing:{
                listing: {
                    value: []
                }
            },
            image: null,
            image_warnings: []
        };

        this.onDrop = this.onDrop.bind(this);
        this.myUploadProgress = this.UploadProgress.bind(this);
        this.updateFileProgress = this.updateFileProgress.bind(this);
        this.saveImageToListing = this.saveImageToListing.bind(this);
        this.handleBrowserUploaderClick = this.handleBrowserUploaderClick.bind(this);
        this.onSingleInput = this.onSingleInput.bind(this);
        this.onSizeValidation = this.onSizeValidation.bind(this);
        this.clearWarnings = this.clearWarnings.bind(this);
    }

    saveImageToListing(data)
    {
        const updatedForm = {
            ...this.state.imageListing
        }
        const updateFormElement = {
            ...updatedForm['listing']
        }
        updatedForm['listing'] = updateFormElement; 
        updateFormElement.value = data;
        this.setState({form:updatedForm});
    }

    updateFileProgress(fileId,progress)
    {

        const updatedForm = {
            ...this.state.imageListing
        }
        const updateFormElement = {
            ...updatedForm['listing']
        }
        updatedForm['listing'] = updateFormElement; 
        const foundIndex = updateFormElement.value.findIndex(x => x.id === fileId);
        updateFormElement.value[foundIndex] = {
            ...updateFormElement.value[foundIndex],
            progress: progress
        };
        this.setState({imageListing:updatedForm})
    }

    UploadProgress(fileId)
    {
        return function(progress) {
            let percentage = Math.floor((progress.loaded * 100) / progress.total);
            //update file progress
            this.updateFileProgress(fileId, percentage);

        }.bind(this)
    }

    async onDrop(acceptedFiles) 
    {
        this.clearWarnings();
        const router = Router(this.props.data.routes);
        acceptedFiles.map(async file =>  {
            const data = new FormData() 
            data.append('image', file);


            if(this.onSizeValidation(file)){
                const fileId = uniqid();
                const stateData = this.state.imageListing.listing.value;
                stateData.push({
                    id: fileId,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    progress: 0
                });
    
                this.saveImageToListing(stateData);
    
                const config = {
                    headers: {
                        'Content-Type': file.type,
                    },
                    onUploadProgress: this.UploadProgress(fileId)
                }
    
                await axios.post(router.url('media.store'), data,config)
                .then((response) => {
                    console.log('media.store', response)
                }).catch(err => console.log(err));
            }

        });
        
    }

    handleBrowserUploaderClick() 
    {
        this.refs.browserFileUploader.click();
    }

    onSingleInput(e)
    {
        this.clearWarnings();
        const file = [e.target.files[0]];
        if(this.onSizeValidation(file)){
            //Formating to onDrop function
            this.onDrop(file);
        }
    }

    clearWarnings()
    {
        if(this.state.image_warnings.length > 0){
            this.setState({image_warnings:[]})
        }
    }

    onSizeValidation(file)
    {
        const file_size = ((file.size/1024)/1024).toFixed(4);
        
        if(file_size > (this.props.data.upload_size)) 
        {
            //Show warning
            const warning_data = this.state.image_warnings;
            warning_data.push(file);
            this.setState({image_warnings:warning_data});
            return false
        }
        return true
    }

    render() {

        const { upload_size } = this.props.data;
        
        return (
            <div>
                <div className="card border border-dashed border-3px">
                <Dropzone onDrop={this.onDrop} >
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()} className={'file_dropzone'}>
                        <div>
                        <input {...getInputProps()} />
                        <p>Drag files to upload</p>
                        <p>or</p>
                        <span className={'btn btn-primary file_input_button'}>Select Files</span>
                        </div>
                    </div>
                    </section>
                )}
                </Dropzone>
                </div>
                <div className={'file_upload_info'}>
                    <p>You are using the multi-file uploader. Problem? Try the <a href='javascript:;' onClick={()=>this.handleBrowserUploaderClick()}>browser uploader</a> instead.</p>
                    <p>Maximum upload file size: {upload_size} MB.</p>
                    <input type="file" ref="browserFileUploader" style={{display: "none"}} onChange={this.onSingleInput}/>
                </div>
                {this.state.image_warnings.length > 0 && <div className="alert alert-danger" role="alert"> <p>File is too big</p>
                {this.state.image_warnings.map((file,key) => (
                        <li key={key}>{file.name} - {file.size+' bytes'}</li>
                ))}
                </div>}
                
                <div className="card z-depth-0">
                    <div className="card-body p-0">
                        <div className={'list-group list-group-flush'}>
                            {
                                this.state.imageListing.listing.value.map((file, key) => (
                                    <li 
                                        key={key}
                                        className="list-group-item"
                                    >
                                        <div className="row">
                                            <div className="col-10">
                                                <div>{file.name} - {file.size+' bytes'}</div>
                                            </div>
                                            <div className="col-2">
                                                <div className="progress w-100">
                                                    <div className="progress-bar" role="progressbar" style={{width: file.progress+'%'}} aria-valuenow={file.progress} aria-valuemin="0" aria-valuemax="100">{file.progress+'%'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );

   
    }
}

if (document.getElementById('mediaUploader')) {
    const element = document.getElementById('mediaUploader');
    const data = Object.assign({}, element.dataset);
    ReactDOM.render(<ErrorBoundary><MediaUploader data={JSON.parse(data.data)}/></ErrorBoundary>, document.getElementById('mediaUploader'));
}
