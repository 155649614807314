import { API, Helpers, Router, Translator } from '../Helpers';
import { Bar, Doughnut } from '@reactchartjs/react-chart.js';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        
        const { active_site, csrf_token, env, request, routes, user } = this.props.data;
        const router = Router(routes);
        
        const bar_data = {
            labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            datasets: [
                {
                    label: "Merchant One",
                    backgroundColor: "blue",
                    data: [3,3,5,6,7,8,9]
                },
                {
                    label: "Adyen",
                    backgroundColor: "red",
                    data: [1,2,3,4,5,6,7]
                }
            ]
        };

        const bar_options = {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            maintainAspectRatio: false
        };
          
        const donut_data = {
            labels: ['Merchant One', 'Adyen'],
            datasets: [
              {
                label: '# of Votes',
                data: [70, 30],
                backgroundColor: [
                  "blue",
                  "red"
                ]
              },
            ]
        };
          
        const donut_options = {
            maintainAspectRatio: false
        };
     
        return (
            <div className="d-none">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="font-weight-bold text-uppercase">Transactions today</div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-3">
                            <div className="card border rounded-0">
                                <div className="card-body text-center p-4">
                                    <h2 className="font-weight-bold mb-2"><span className="font-largest">$3,110</span></h2>
                                    <div className="mb-4">Merchant One</div>
                                    
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped bg-primary" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="card border rounded-0">
                                <div className="card-body text-center p-4">
                                    <h2 className="font-weight-bold mb-2"><span className="font-largest">$7,500</span></h2>
                                    <div className="mb-4">Adyen</div>
                                    
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped green" role="progressbar" style={{ width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">Unlimited</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="font-weight-bold text-uppercase">Past 7 days</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="card border rounded-0">
                                <div className="card-body p-4" style={{ height: '350px' }}>
                                    <Bar data={bar_data} options={bar_options} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="card border rounded-0 h-100">
                                <div className="card-body p-4">
                                    <Doughnut data={donut_data} options={donut_options} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('home')) {
    
    const element = document.getElementById('home');
    const data = Object.assign({}, element.dataset);
    
    ReactDOM.render(<ErrorBoundary><App data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('home'));
}
