import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class BookingInformation extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { booking, router } = this.props;
        
        return (
            <div className="card h-100 border rounded-0">
                <div className="card-header white p-4">
                    <h4 className="mb-0 font-weight-bold"><i className="fa fa-fw fa-lg fa-star mr-2"></i>Booking information</h4>
                </div>
                <div className="card-body p-4">
                    <table className="w-100 mb-4">
                        <tbody>
                            <tr>
                                <td className="w-50">Version</td>
                                <td className="w-50">{booking.version}</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>{moment(booking.reservation_date_time).format('DD/MM/YYYY HH:ss')}</td>
                            </tr>
                            <tr>
                                <td>Supplier reference</td>
                                <td><span className="font-weight-bold">{!_.isEmpty(booking.supplier_reference) ? booking.supplier_reference: 'No supplier reference'}</span></td>
                            </tr>
                            <tr>
                                <td>Broker reference</td>
                                <td><span className="font-italic">{!_.isEmpty(booking.brokerReference) ? booking.brokerReference: 'No broker reference'}</span></td>
                            </tr>
                            <tr>
                                <td>Agent</td>
                                <td><span className="font-weight-bold">{booking.source}</span></td>
                            </tr>
                            <tr>
                                <td>Agency</td>
                                <td><a href="#">{'--'}</a></td>
                            </tr>
                            <tr>
                                <td>User</td>
                                <td>{'--'}</td>
                            </tr>
                            <tr>
                                <td>Contract</td>
                                <td>
                                    <a href={router.url('office.contracts.edit', {id: booking.vehicle.package.contract_id})} target="_blank">{booking.vehicle.package.contract_id}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td className="w-50">Pick-up location</td>
                                <td className="w-50"><a href={router.url('office.branches.show', {id: booking.pickup_location.location_id})}>{booking.pickup_location.unique_name}</a></td>
                            </tr>
                            <tr>
                                <td>Pick-up datetime</td>
                                <td>{moment(booking.pickup_date_time).format('DD/MM/YYYY HH:ss')}</td>
                            </tr>
                            <tr>
                                <td>Drop-off location</td>
                                <td><a href={router.url('office.branches.show', {id: booking.dropoff_location.location_id})}>{booking.dropoff_location.unique_name}</a></td>
                            </tr>
                            <tr>
                                <td>Drop-off datetime</td>
                                <td>{moment(booking.dropoff_date_time).format('DD/MM/YYYY HH:ss')}</td>
                            </tr>
                            <tr>
                                <td>Duration</td>
                                <td>{booking.vehicle.package.rental_days} day (s)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
