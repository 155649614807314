import PerfectScrollbar from 'perfect-scrollbar';
import swal from 'sweetalert';

window.moment = require('moment');
window.numeral = require('numeral');
window.uniqid = require('uniqid');
window.PerfectScrollbar = PerfectScrollbar;
window.pluralize = require('pluralize');
window.toastr = require('toastr');
window.URI = require('urijs');

require('./bootstrap');
require('./webfont');

require('jquery-ui');
require('icheck');
require('jquery-lazy');
require('froala-editor');
require('bootstrap-colorpicker');
require('datepicker');
require('jquery-form');
require('jquery-validation');
require('slick-carousel');
require('select2');
require('waypoints');

// Containers
require('./components/Home/App');
require('./components/BookingsIndex/App');
require('./components/BrokerReferences/Index');
require('./components/BrokerReferences/Show');
require('./components/Locations/Index');
require('./components/MediaUploader/App');
require('./components/Users/Index');
