import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class PhoneNumber extends Component
{
    
    
    render()
    {
        return (
                <span>{this.props.person.cellPhone || this.props.person.homePhone}</span>
                );
    }
}
