import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TableColumns extends Component
{
    render()
    {
        const { broker_references } = this.props;

        return (
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Location ID</th>
                <th scope="col">Location type</th>
                <th scope="col">Code</th>
                <th scope="col">Country</th>
                <th scope="col">City</th>
                <th scope="col"></th>
            </tr>
        );
    }
}
