import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class InputFile extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            id: uniqid(),
            filename: this.props.filename || 'Choose file'
        };
        
        this.setFilename = this.setFilename.bind(this);
    }
    
    setFilename(event)
    {
        let filename = event.target.files[0].name;
        
        this.setState({
            filename: filename
        });
    }
    
    render()
    {
        return (
                <div>
                    <label htmlFor={this.props.name}>{this.props.label}</label>
                    <div className="custom-file overflow-hidden">
                        <input 
                            type="file" 
                            name={this.props.name} 
                            defaultValue={this.props.defaultValue} 
                            className="custom-file-input" 
                            id={"fileInput_" + this.state.id}
                            onChange={this.setFilename}
                        />
                        <label className="custom-file-label" htmlFor={"fileInput_" + this.state.id}>{this.state.filename}</label>
                    </div>
                </div>
                );
    }
}
