import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class TextArea extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    <textarea 
                        {...this.props}
                        name={this.props.name} 
                        defaultValue={this.props.defaultValue} 
                        type="text" 
                        className="form-control"
                        rows={this.props.rows}
                        onChange={this.props.onChange}
                    />
                </div>
                );
    }
}
