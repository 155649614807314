import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Datepicker extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            uniqid: 'datepicker_' + uniqid()
        };
    }
    
    componentDidMount()
    {
        $('#' + this.state.uniqid).datepicker(this.props.options || {});
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    <div className="input-group">
                        {
                            this.props.prepend &&
                            <div className="input-group-prepend">
                                <span className="input-group-text">{this.props.prepend}</span>
                            </div>
                        }
                        
                        <input 
                            id={this.state.uniqid}
                            name={this.props.name} 
                            defaultValue={this.props.defaultValue} 
                            type="text" 
                            className="form-control"
                            placeholder={this.props.placeholder} 
                            data-toggle="datepicker" 
                        />
                        
                        {
                            this.props.append &&
                            <div className="input-group-append">
                                <span className="input-group-text">{this.props.append}</span>
                            </div>
                        }
                    </div>
                </div>
                );
    }
}
