import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class VehicleDetails extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { booking, env, router } = this.props;
        
        return (
            <div className="table-responsive">
                <table className="table table-sm ">
                    <thead>
                        <tr>
                            <th colSpan="2">Vehicle name - ACRISS</th>
                            <th>Supplier</th>
                            <th>Price</th>
                            <th>Net rate</th>
                            <th>Exchange rate (From / To)</th>
                            <th>Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-active">
                            <td width="60">
                                <div className="avatar avatar-medium avatar-rounded d-block mx-auto">
                                    <img className="img-fluid" src={booking.vehicle.image.small} />
                                </div>
                            </td>
                            <td>
                                <strong>{booking.vehicle.name} - {booking.vehicle.acriss_code}</strong> <br/>
                                <a href="#termsConditionsModal" data-toggle="modal"><span className="font-small btn-link">Terms & Conditions</span></a>
                            </td>
                            <td><strong>{booking.vehicle.supplier.name}</strong></td>
                            <td>
                                <a href="#">{booking.vehicle.package.vehiclePrice.total.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.vehiclePrice.total.payment.amount, booking.vehicle.package.vehiclePrice.total.payment.currency, env.APP_LOCALE)}</a>
                                <br/>
                                <span className="text-muted small">Discount: -- </span>
                            </td>
                            <td>{booking.vehicle.package.vehiclePrice.net.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.vehiclePrice.net.payment.amount, booking.vehicle.package.vehiclePrice.net.payment.currency, env.APP_LOCALE)}</td>
                            <td>--</td>
                            <td className="font-weight-normal">
                                {booking.vehicle.package.vehiclePrice.net.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.vehicle_profit, booking.vehicle.package.vehiclePrice.net.payment.currency, env.APP_LOCALE)}
                            </td>
                        </tr>
                        <tr className="table-active">
                            <td colSpan="7">
                                <ul className="list-inline">
                                    <li className="list-inline-item pr-4">Vehicle type: <br/><span className="font-weight-bold">{booking.vehicle.category}</span></li>
                                    <li className="list-inline-item pr-4">Seats: <br/><span className="font-weight-bold">{booking.vehicle.seats}</span></li>
                                    <li className="list-inline-item pr-4">Doors: <br/><span className="font-weight-bold">{booking.vehicle.doors}</span></li>
                                    {booking.vehicle.luggage_space > 0 && <li className="list-inline-item pr-4">Big suitcases: <br/><span className="font-weight-bold">{booking.vehicle.luggage_space}</span></li>}
                                    {booking.vehicle.small_suitcases > 0 && <li className="list-inline-item pr-4">Small suitcases: <br/><span className="font-weight-bold">{booking.vehicle.small_suitcases}</span></li>}
                                    {booking.vehicle.large_suitcases > 0 && <li className="list-inline-item pr-4">Large suitcases: <br/><span className="font-weight-bold">{booking.vehicle.large_suitcases}</span></li>}
                                    <li className="list-inline-item pr-4">Transmission: <br/><span className="font-weight-bold">{booking.vehicle.transmission}</span></li>
                                    <li className="list-inline-item pr-4">Fuel Policy: <br/><span className="font-weight-bold">{booking.vehicle.package.fuel_policy.name}</span></li>
                                    <li className="list-inline-item pr-4">Mileage info: <br/>
                                        <span className="font-weight-bold">
                                            --
                                        </span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
