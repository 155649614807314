import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from '../../withErrorBoundary/withErrorBoundary';
import { InputHidden, TextArea } from "../Core";

export default class Show extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.showConfirmVoidTransactionModal = this.showConfirmVoidTransactionModal.bind(this);
        this.showVoidTransactionLoader = this.showVoidTransactionLoader.bind(this);
        this.toggleConfirmVoidTransaction = this.toggleConfirmVoidTransaction.bind(this);
    }

    showConfirmVoidTransactionModal()
    {
        $('#confirmVoidTransactionModal').modal('show');
    }

    showVoidTransactionLoader()
    {
        console.log("showVoidTransactionLoader");

        this.setState({
            show_void_transaction_loader: true
        });
    }

    toggleConfirmVoidTransaction()
    {
        this.setState({
            confirm_void_transaction: !this.state.confirm_void_transaction
        });
    }

    render() {

        const { active_site, alert, booking, booking_broker_references, broker_reference, csrf_token, env, payment_providers, request, routes, user } = this.props.data;
        const router = Router(routes);

        return (
            <div>
                <div className="container-fluid">
                    {
                        !_.isEmpty(alert) &&
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className={"alert mb-3 " + alert.type} role="alert">
                                    <div>
                                        <h4 className="font-weight-bold"><i className={"fa-lg mr-2 " + alert.icon}></i>{alert.heading}</h4>
                                        <div>{alert.message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mb-3">
                        <div className="col-12">
                            <h2>Broker Reference Detail</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ul className="list-inline mb-0 d-flex align-items-center">
                                <li className="list-inline-item">
                                    <span className="font-weight-bold font-larger mr-2">ID: {broker_reference.id}</span>
                                </li>

                                <li className="list-inline-item">
                                    <div className="badge badge-success z-depth-0 p-2">
                                        <span className="font-weight-normal">{broker_reference.statusName}</span>
                                    </div>
                                </li>

                                {
                                    broker_reference.captured &&
                                    <li className="list-inline-item">
                                        <div className="badge badge-secondary z-depth-0 p-2">
                                            <span className="font-weight-normal">Captured</span>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-12">
                            <div className="row mb-4">
                                <div className="col-6">
                                    <div className="card border rounded-0 mb-4">
                                        <div className="card-header white p-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <h4 className="mb-0 font-weight-bold">
                                                        <i className="fas fa-fw fa-lg fa-file-invoice-dollar mr-2"></i>Transaction information
                                                    </h4>
                                                </div>
                                                <div>
                                                    {
                                                        !_.isEmpty(broker_reference.transactionId) &&
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-flat dropdown-toggle no-caret"
                                                                type="button"
                                                                data-toggle="dropdown">
                                                                <i className="fas fa-ellipsis-v"></i>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={this.showConfirmVoidTransactionModal}>
                                                                    Void transaction
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body p-4">
                                            <table className="w-100 mb-0">
                                                <tbody>
                                                    {
                                                        !_.isEmpty(broker_reference.transactionId) &&
                                                        <tr>
                                                            <td className="w-50">Transaction ID</td>
                                                            <td className="w-50 font-weight-bold">{broker_reference.transactionId}</td>
                                                        </tr>
                                                    }

                                                    <tr>
                                                        <td className="w-50">Session ID</td>
                                                        <td className="w-50 font-weight-bold">{broker_reference.sessionId}</td>
                                                    </tr>

                                                    <tr>
                                                        <td className="align-middle">Date</td>
                                                        <td className="align-middle">{Helpers.toDateString(broker_reference.created)} {Helpers.toTimeString(broker_reference.created)}</td>
                                                    </tr>

                                                    {
                                                        !_.isEmpty(broker_reference.cardNumber) &&
                                                        <tr>
                                                            <td>CC Number</td>
                                                            <td>{broker_reference.cardNumber}</td>
                                                        </tr>
                                                    }

                                                    <tr>
                                                        {
                                                            broker_reference.authAmount > 0 &&
                                                            <td className="align-middle">Payment</td>
                                                        }

                                                        {
                                                            broker_reference.refundAmount > 0 &&
                                                            <td className="align-middle">Refund</td>
                                                        }

                                                        <td className="align-middle">
                                                            {
                                                                broker_reference.authAmount > 0 &&
                                                                <div className="text-muted">{Helpers.formatCurrency(broker_reference.authAmount, broker_reference.currency, env.APP_LOCALE)}</div>
                                                            }

                                                            {
                                                                broker_reference.refundAmount > 0 &&
                                                                <div className="text-muted">{Helpers.formatCurrency(broker_reference.refundAmount, broker_reference.currency, env.APP_LOCALE)}</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle">Currency</td>
                                                        <td className="align-middle">{broker_reference.currency}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="card border rounded-0 mb-4">
                                        <div className="card-header white p-4">
                                            <h4 className="mb-0 font-weight-bold">
                                                <i className="far fa-fw fa-lg fa-user mr-2"></i>Customer information
                                            </h4>
                                        </div>
                                        <div className="card-body p-4">
                                            <table className="w-100 mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td className="w-50">Customer name</td>
                                                        <td className="w-50"><a href="#" target="_blank" className="btn-link text-primary font-weight-bold">{broker_reference.firstName} {broker_reference.lastName}</a></td>
                                                    </tr>
                                                    <tr>
                                                        <td>E-mail</td>
                                                        <td>{broker_reference.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Phone</td>
                                                        <td>{broker_reference.phone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Postal</td>
                                                        <td>{broker_reference.postal}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shopper IP</td>
                                                        <td>{broker_reference.shopperIp}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    {
                                        !_.isEmpty(booking) &&
                                        <div>
                                            <div className="font-weight-bold text-uppercase mb-2"><i className="fas fa-car mr-2"></i>Booking</div>

                                            <div className="card border mb-4">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <div className="mb-1">
                                                                <span className="font-weight-bold mr-2">Booking Number:</span><span>#{booking.reservNum}</span>
                                                            </div>
                                                            <div>
                                                                <span className="font-small text-muted text-uppercase mr-2">{booking.status}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a className="btn btn-primary" target="_blank" href={router.url('office.bookings.show', {reserv_num: booking.reservNum})}>
                                                                View details
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="font-weight-bold text-uppercase mb-2"><i className="far fa-clock mr-2"></i>Transaction History</div>

                                    {
                                        _.isEmpty(booking_broker_references) ?
                                        <div className="py-3">
                                            Nothing to see here
                                        </div> :
                                        <div>
                                            {
                                                booking_broker_references[0].cancelAmount > 0 &&
                                                <div className="mb-4">
                                                    <div className="accordion" id="cancellationAccordion">
                                                        <div className="card z-depth-0 bordered alert-danger">
                                                            <div className="card-header">
                                                                <div
                                                                    data-toggle="collapse"
                                                                    data-target="#cancellationCollapse"
                                                                    aria-expanded="true">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h5>{Helpers.toShortDayDateTimeString(booking_broker_references[0].created)}</h5>
                                                                            <div>cancel_booking</div>
                                                                        </div>

                                                                        <div>
                                                                            <div className="mb-1 text-right">Refund</div>
                                                                            <div className="font-small text-muted text-right">{Helpers.formatCurrency(booking_broker_references[0].cancelAmount, booking_broker_references[0].currency, env.APP_LOCALE)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="cancellationCollapse" className="collapse" data-parent="#cancellationAccordion">
                                                                <div className="card-body white">
                                                                    {
                                                                        !_.isEmpty(booking.cancellation_fee) &&
                                                                        <div>
                                                                            <div>
                                                                                <span className="font-weight-bold mr-2">Cancellation Fee:</span>{Helpers.formatCurrency(booking.cancellation_fee.amount, broker_reference.currency, env.APP_LOCALE)}
                                                                            </div>

                                                                            {
                                                                                !_.isEmpty(booking.cancellation_fee.extra) &&
                                                                                <div className="mt-4">
                                                                                    <table className="table mb-0">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col">Type</th>
                                                                                                <th scope="col">Item</th>
                                                                                                <th scope="col">OTA Code
                                                                                                </th>
                                                                                                <th scope="col">Price</th>
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            <tr>
                                                                                                <td>Extra</td>
                                                                                                <td>{booking.cancellation_fee.extra.name}</td>
                                                                                                <td>{booking.cancellation_fee.extra.code}</td>
                                                                                                <td>{Helpers.formatCurrency(booking.cancellation_fee.extra.rental_price.payment.amount, booking.cancellation_fee.extra.rental_price.payment.currency, env.APP_LOCALE)}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            {
                                                !_.isEmpty(booking_broker_references) &&
                                                <div className="accordion" id="historyAccordion">
                                                    {
                                                        booking_broker_references.map((booking_broker_reference, key) => {

                                                            return (
                                                                <div key={key} className={booking_broker_reference.id == broker_reference.id ? "card z-depth-0 bordered alert-primary" : "card z-depth-0 bordered"}>
                                                                    <div className="card-header cursor-pointer">
                                                                        <div
                                                                            data-toggle="collapse"
                                                                            data-target={"#collapse_" + booking_broker_reference.id}>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <div>
                                                                                    <h5>{Helpers.toShortDayDateTimeString(booking_broker_reference.created)}</h5>

                                                                                    {
                                                                                        booking_broker_reference.authAmount > 0 &&
                                                                                        <div>
                                                                                            {
                                                                                                (booking_broker_references.length - (key + 1)) == 0 &&
                                                                                                <div>create_booking</div>
                                                                                            }

                                                                                            {
                                                                                                (booking_broker_references.length - (key + 1)) > 0 &&
                                                                                                <div>modify_booking</div>
                                                                                            }
                                                                                        </div>
                                                                                    }

                                                                                    {
                                                                                        booking_broker_reference.refundAmount > 0 &&
                                                                                        <div>
                                                                                            <div>modify_booking</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div>
                                                                                    {
                                                                                        booking_broker_reference.authAmount > 0 &&
                                                                                        <div>
                                                                                            <div className="mb-1 text-right">Payment</div>
                                                                                            <div className="font-small text-muted text-right">{Helpers.formatCurrency(booking_broker_reference.authAmount, booking_broker_reference.currency, env.APP_LOCALE)}</div>
                                                                                        </div>
                                                                                    }

                                                                                    {
                                                                                        booking_broker_reference.refundAmount > 0 &&
                                                                                        <div>
                                                                                            <div className="mb-1 text-right">Refund</div>
                                                                                            <div className="font-small text-muted text-right">{Helpers.formatCurrency(booking_broker_reference.refundAmount, booking_broker_reference.currency, env.APP_LOCALE)}</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div id={"collapse_" + booking_broker_reference.id} className="collapse" data-parent="#historyAccordion">
                                                                        <div className="card-body">
                                                                            <div className="row mb-4">
                                                                                <div className="col-12 d-flex align-items-end">
                                                                                    <ul className="list-inline mb-0 d-flex align-items-center">
                                                                                        <li className="list-inline-item">
                                                                                            <a className="btn-link" href={router.url('broker_references.show', {broker_reference: booking_broker_reference.id})}>
                                                                                                <span className="font-weight-bold mr-2">ID: {booking_broker_reference.id}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                !_.isEmpty(booking_broker_reference.transactionId) &&
                                                                                <div className="mb-2">
                                                                                    <span className="font-weight-bold mr-2">Transaction ID:</span>{booking_broker_reference.transactionId}
                                                                                </div>
                                                                            }

                                                                            {
                                                                                !_.isEmpty(booking_broker_reference.sessionId) &&
                                                                                <div className="mb-2">
                                                                                    <span className="font-weight-bold mr-2">Session ID:</span>{booking_broker_reference.sessionId}
                                                                                </div>
                                                                            }

                                                                            <div className="mb-2">
                                                                                <span className="font-weight-bold mr-2">Date:</span>
                                                                                <span>{Helpers.toDateString(booking_broker_reference.created)} {Helpers.toTimeString(booking_broker_reference.created)}</span>
                                                                            </div>

                                                                            {
                                                                                !_.isEmpty(booking_broker_reference.cardNumber) &&
                                                                                <div className="mb-2">
                                                                                    <span className="font-weight-bold mr-2">CC Number:</span>{booking_broker_reference.cardNumber}
                                                                                </div>
                                                                            }

                                                                            {
                                                                                booking_broker_reference.authAmount > 0 &&
                                                                                <div className="mb-2">
                                                                                    <span className="font-weight-bold mr-2">Payment:</span>{Helpers.formatCurrency(booking_broker_reference.authAmount, booking_broker_reference.currency, env.APP_LOCALE)}
                                                                                </div>
                                                                            }

                                                                            {
                                                                                booking_broker_reference.refundAmount > 0 &&
                                                                                <div className="mb-2">
                                                                                    <span className="font-weight-bold mr-2">Refund:</span>{Helpers.formatCurrency(booking_broker_reference.refundAmount, booking_broker_reference.currency, env.APP_LOCALE)}
                                                                                </div>
                                                                            }

                                                                            <div className="mb-0">
                                                                                <span className="font-weight-bold mr-2">Currency:</span>{booking_broker_reference.currency}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="confirmVoidTransactionModal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0 pb-0">
                                <button type="button " className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-5 pb-5 pt-0">
                                <div>
                                    <div className="font-largest font-weight-bold mb-4">Void transaction</div>

                                    <div>
                                        <div className="row">
                                            <div className="col-12">
                                                <form
                                                    action={router.url('broker_references.void')}
                                                    method="POST"
                                                    onSubmit={this.showVoidTransactionLoader}
                                                >
                                                    <InputHidden name="_token" defaultValue={csrf_token}/>
                                                    <InputHidden name="redirect_to" defaultValue={window.location.href}/>
                                                    <InputHidden name="broker_references[]" defaultValue={broker_reference.id} />
                                                    <InputHidden name="user_id" defaultValue={user.user.id}/>

                                                    <p>This will void Transaction ID: <span className="font-weight-bold">{broker_reference.transactionId}</span></p>

                                                    <p className="mb-4">This CANNOT be undone!</p>

                                                    <div className="form-group">
                                                        <TextArea
                                                            name="comment"
                                                            label="Comment"
                                                            rows={3}
                                                        />
                                                    </div>

                                                    <div className="custom-control custom-checkbox mb-4">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            id="confirmReset"
                                                            onChange={this.toggleConfirmVoidTransaction}
                                                        />
                                                        <label className="custom-control-label" htmlFor="confirmReset">Yes, I would like to void this transaction.</label>
                                                    </div>

                                                    <div className="row form-row">
                                                        <div className="col-6">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-block btn-danger py-3 font-weight-bold"
                                                                disabled={!this.state.confirm_void_transaction}>
                                                                <ul className="list-inline mb-0 d-flex align-items-center justify-content-center">
                                                                    {
                                                                        this.state.show_void_transaction_loader &&
                                                                        <li className="list-inline-item mr-2">
                                                                            <div className="spinner-border spinner-border-sm" role="status"></div>
                                                                        </li>
                                                                    }

                                                                    <li className="list-inline-item">Void transaction</li>
                                                                </ul>
                                                            </button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button className="btn btn-block btn-outline-secondary py-3 font-weight-bold" data-dismiss="modal">Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById('brokerReferencesShow')) {

    const element = document.getElementById('brokerReferencesShow');
    const data = Object.assign({}, element.dataset);

    //console.log(data.data);

    ReactDOM.render(<ErrorBoundary><Show data={JSON.parse(data.data)} /></ErrorBoundary>, document.getElementById('brokerReferencesShow'));
}
