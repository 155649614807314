import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class PdfObject extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }
    
    componentDidMount() {
        
        var options = {
            pdfOpenParams: { zoom: 'FitH' }
        }
        
        PDFObject.embed(this.props.src, "#" + this.props.container, options);
    }
    
    render()
    {
        return (
                <div>
                    <div id={this.props.container} style={{height: this.props.height}}></div>
                </div>
                );
    }
}
