import { API, Helpers, Router, Translator } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class PriceSummary extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    
    render()
    {
        const id = uniqid();
        const { booking, env, router } = this.props;
        
        return (
            <div>
                <h4 className="mb-0 font-weight-bold"><span className="font-weight-bold">Price summary</span></h4>

                <div className="row">
                    <div className="col-12">
                        <table className="table table-sm">
                            <thead>
                                <tr className="font-bold">
                                    <th colSpan="2">Item</th>
                                    <th colSpan="2">Pay now</th>
                                    <th colSpan="2">Pay local</th>
                                    <th>Total</th>
                                    <th>Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Vehicle</td>
                                    <td rowSpan="5" className="table-active align-middle text-center font-weight-bold">:</td>
                                    <td>{booking.vehicle.package.payments.payNow.vehicle.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payNow.vehicle.payment.amount, booking.vehicle.package.payments.payNow.vehicle.payment.currency, env.APP_LOCALE)}</td>
                                    <td rowSpan="5" className="table-active align-middle text-center font-weight-bold">+</td>
                                    <td>{booking.vehicle.package.payments.payLocal.vehicle.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payLocal.vehicle.payment.amount, booking.vehicle.package.payments.payLocal.vehicle.payment.currency, env.APP_LOCALE)}</td>
                                    <td rowSpan="5" className="table-active align-middle text-center font-weight-bold">=</td>
                                    <td>{booking.vehicle.package.payments.estimatedTotal.vehicle.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.estimatedTotal.vehicle.payment.amount, booking.vehicle.package.payments.estimatedTotal.vehicle.payment.currency, env.APP_LOCALE)} <span className="text-muted">(Estimated)</span></td>
                                    <td className="text-right note note-warning border-0">
                                        {booking.vehicle.package.vehiclePrice.net.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.vehicle_profit, booking.vehicle.package.vehiclePrice.net.payment.currency, env.APP_LOCALE)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Extras</td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payNow.extras.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payNow.extras.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payNow.extras.payment.amount, booking.vehicle.package.payments.payNow.extras.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payLocal.extras.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payLocal.extras.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payLocal.extras.payment.amount, booking.vehicle.package.payments.payLocal.extras.payment.currency, env.APP_LOCALE)}
                                            </div> : 
                                            <div>--</div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.estimatedTotal.extras.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.estimatedTotal.extras.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.estimatedTotal.extras.payment.amount, booking.vehicle.package.payments.estimatedTotal.extras.payment.currency, env.APP_LOCALE)} <span className="text-muted">(Estimated)</span>
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                    <td className="text-right note note-warning border-0">
                                        {
                                            booking.vehicle.package.payments.payNow.extras.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payNow.extras.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.extras_profit, booking.vehicle.package.payments.payNow.extras.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fees & coverages</td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payNow.fees.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payNow.fees.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payNow.fees.payment.amount, booking.vehicle.package.payments.payNow.fees.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payLocal.fees.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payLocal.fees.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payLocal.fees.payment.amount, booking.vehicle.package.payments.payLocal.fees.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>   
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.estimatedTotal.fees.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.estimatedTotal.fees.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.estimatedTotal.fees.payment.amount, booking.vehicle.package.payments.estimatedTotal.fees.payment.currency, env.APP_LOCALE)} <span className="text-muted">(Estimated)</span>
                                            </div> :
                                            <div>--</div>   
                                        }
                                    </td>
                                    <td className="text-right note note-warning border-0">--</td>
                                </tr>
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payNow.total.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payNow.total.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payNow.total.payment.amount, booking.vehicle.package.payments.payNow.total.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>   
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.payLocal.total.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.payLocal.total.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.payLocal.total.payment.amount, booking.vehicle.package.payments.payLocal.total.payment.currency, env.APP_LOCALE)}
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            booking.vehicle.package.payments.estimatedTotal.total.payment.amount > 0 ?
                                            <div>
                                                {booking.vehicle.package.payments.estimatedTotal.total.payment.currency} {Helpers.formatCurrency(booking.vehicle.package.payments.estimatedTotal.total.payment.amount, booking.vehicle.package.payments.estimatedTotal.total.payment.currency, env.APP_LOCALE)} <span className="text-muted">(Estimated)</span>
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                    <td className="text-right note note-warning border-0 font-weight-normal">
                                        {
                                            booking.vehicle.package.payments.estimatedTotal.total.payment.amount > 0 ?
                                            <div>
                                                <div>{booking.vehicle.package.payments.estimatedTotal.total.payment.currency} {Helpers.formatCurrency(this.props.totalProfit(), booking.vehicle.package.payments.estimatedTotal.total.payment.currency, env.APP_LOCALE)}</div>
                                                <div className="text-muted font-smaller">USD -- (commission)</div>
                                                <hr/>

                                                <strong>{booking.vehicle.package.payments.estimatedTotal.total.payment.currency} {Helpers.formatCurrency(this.props.totalProfit(), booking.vehicle.package.payments.estimatedTotal.total.payment.currency, env.APP_LOCALE)}</strong>
                                            </div> :
                                            <div>--</div>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
