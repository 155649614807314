import React, {Component} from 'react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

class WithErrorBoundary extends Component {

  componentDidMount(){

    try{
      const {SENTRY_LARAVEL_DSN,APP_ENV} = this.props.children.props.data.env;
      Sentry.init({
        dsn: SENTRY_LARAVEL_DSN,
        integrations: [new Integrations.BrowserTracing()], //Website performance
        tracesSampleRate: 1.0,
        environment: APP_ENV
      });

    }catch(e){
      console.log('Error', JSON.stringify(e))
    }
    
  }

  componentDidCatch(error, info) {
    Sentry.configureScope(scope => scope.setUser(null));
  }

  render() {
    return this.props.children
  }
}
export default Sentry.withProfiler(WithErrorBoundary);