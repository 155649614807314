import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Select extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
        
        this.onChange = this.onChange.bind(this);
    }
    
    onChange(event)
    {
        const value = event.target.value;
        
        this.props.onChange(value);
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
        
                    <select
                        name={this.props.name}
                        className={this.props.className ? this.props.className : "browser-default custom-select"}
                        defaultValue={this.props.defaultValue} 
                        onChange={this.props.onChange ? this.onChange : undefined}
                        disabled={this.props.disabled}
                    >
                        {
                            this.props.placeholder &&
                            <option value="">{this.props.placeholder}</option>
                        }
                    
                        {
                            _.each(this.props.options).map((option, key) =>
                                <option key={key} value={option.value}>{option.text}</option>
                            )
                        }
                    </select>
                </div>
                );
    }
}
