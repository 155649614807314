import { API } from '../Helpers';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class Select2 extends Component
{
    constructor(props) {
        super(props);

        this.state = {

        };
        
        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.setDefaultValue = this.setDefaultValue.bind(this);
    }
    
    componentDidMount()
    {
        this.initialize();
        
        if (this.props.defaultValue)
        {
            this.getDefaultValue(this.setDefaultValue, this.props.endpoint + this.props.defaultValue);
        }
        
        if (this.props.onChange)
        {
            $('#' + this.props.id).on('select2:select', (event) => 
            {
                let data = event.params.data;
                
                //console.log('select2:change', data);

                this.props.onChange(data);
            });
        }
    }
    
    getDefaultValue(callback, url)
    {
        //console.log("getDefaultValue", callback, url);
        
        let x = {
            success: (response) => callback(response),
            error: err => 
            {
                console.log('err=>', err);
            },
            complete: () => 
            {
                console.log('complete=>');
            }
        }
        
        API.get(x, url, this.props.token);
    }
    
    initialize()
    {
        // Select2
        
        //console.log("initialize");
        //console.log("defaultValue", this.props.defaultValue);
        
        var $this = $('#' + this.props.id);
        var endpoint = $this.data('endpoint');
        var idField = $this.data('idfield');
        var textField = $this.data('textfield');

        //console.log('fields', idField, textField);

        if (endpoint !== undefined)
        {
            $this.select2({
                theme: "bootstrap4",
                placeholder: $this.attr('placeholder') || $this.data('placeholder') || "choose an option",
                allowClear: true,
                ajax: {
                    url: this.props.endpoint,
                    headers: {
                        //"Authorization": "Bearer " + this.props.token
                    },
                    /*data: function (params)
                    {
                        //console.log(params);

                        var query = {
                            queryFilter: params.term,
                            page: params.page || 1
                        };

                        // Query parameters will be ?search=[term]&page=[page]
                        return query;
                    },
                    processResults: function (results, params)
                    {
                        return results;
                        
                        //console.log(results);
                        //console.log(params);

                        /*var data;
                        var more = false;

                        params.page = results.currentPage || 1;

                        if (results.data !== undefined)
                        {
                            if ((params.page  * results.pageSize) < results.total)
                            {
                                more = true;
                            }
                            
                            results = results.data;
                        }

                        data = $.map(results, (obj) =>
                        {
                            //console.log('fields', idField, textField);
                            //console.log('obj', obj[idField], obj[textField]);

                            obj.id = obj.id || obj[idField];
                            obj.text = obj.text || obj[textField];

                            return obj;
                        });
                        
                        //console.log(data);
                        //console.log(more);

                        return {
                            results: data,
                            pagination: {
                                more: more
                            }
                        };
                    }*/
                }
            }).val($this.val()).trigger('change');
        }
        else
        {
            $this.select2({
                theme: "bootstrap4",
                placeholder: $this.attr('placeholder') || $this.data('placeholder') || "choose an option"
            }).val($this.val()).trigger('change');
        }
    }
    
    setDefaultValue(data)
    {
        //console.log('setDefaultValue', this.props.name, data);
        
        this.setState({
            value: data
        });
    }
    
    render()
    {
        return (
                <div>
                    {
                        this.props.label &&
                        <label htmlFor={this.props.name}>{this.props.label}</label>
                    }
                    
                    <select 
                        id={this.props.id} 
                        name={this.props.name}
                        className="select2 form-control" 
                        data-endpoint={this.props.endpoint}
                        data-idfield={this.props.idfield}
                        data-textfield={this.props.textfield}
                        disabled={this.props.disabled || false}
                        placeholder={this.props.placeholder}
                    >
                        {
                            this.state.value &&
                            <option value={this.state.value[this.props.idfield]}>{this.state.value[this.props.textfield]}</option>
                        }
                    </select>
                </div>
                );
    }
}
