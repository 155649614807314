const buildHeader = () => 
{
    var header = {
        "Accept": "application/json",
        'Content-Type': 'application/json'
    };
    
    return header;
};

export default {
    get: (onResponse, url) => 
    {
        send(onResponse, null, 'GET', url);
    },
    post: (onResponse, payload, url) => 
    {
        send(onResponse, payload, 'POST', url);
    },
    put: (onResponse, payload, url) => 
    {
        send(onResponse, payload, 'PUT', url);
    },
    delete: (onResponse, payload, url) => 
    {
        send(onResponse, payload, 'DELETE', url);
    }
};

async function send(onResponse, payload, requestType, url) 
{
    let response = '';
    let headers = buildHeader();
    
    //console.log('payload', JSON.stringify(payload));

    response = await fetch(url, 
    {
        method: requestType,
        headers: headers,
        body: payload ? JSON.stringify(payload) : null
    });

    if (response.ok)
    {
        //console.log("response.success", response);
        
        const text = await response.text();
        
        if (_.isEmpty(text))
        {
            onResponse.success(null);
        }
        else
        {
            onResponse.success(JSON.parse(text));
        }
    }
    else
    {
        //console.log("response.error", response);

        switch (response.status)
        {
            default: 

                const text = await response.text();
        
                if (_.isEmpty(text))
                {
                    onResponse.error(null);
                }
                else
                {
                    onResponse.error(JSON.parse(text));
                }
        }
    }
}
